import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Teapoy16.css'
import rustcream1 from '../Images/rustcream1.webp'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Teapoy16 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='top-head-accessories' to="/Tables"><h1>TABLES</h1></Link>  
            </div>
            
            <div className="row">
               
                <div className="col-11 col-sm-11 col-md-6 guncol1 order-2 order-md-1">
                {/* <LazyLoadImage className='chikkimirror2' src={chikkimirror2} alt="" loading='lazy' /> */}
                  <div className="product-description-signle-container">
                      <p className='product-description-para-3'><span className='description-heads'>NAME:</span> RUST AND CREAM</p>
                      <p className='product-description-para-3'><span className='description-heads'>DESCRIPTION:</span> Rosewood side teapoy with white marble top.
                      </p>
                  </div>
                </div>
               
                <div className="col-12 col-sm-12 col-md-6 guncol2 order-1 order-md-2">
                <LazyLoadImage className='rustcream1' src={rustcream1} alt="" loading='lazy' />
                </div>
            </div>
            <div className="arrow-container">
           <Link to="/Table42"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /></Link> 
            <Link to="/Table44"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /></Link>
            </div>
        </div>
    </div>
  )
}

export default Teapoy16