import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Drawer7.css'
import redrose1 from '../Drawerimages/redrose1.png'
import arrowright from '../Drawerimages/Arrowright.png'
import arrowleft from '../Drawerimages/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Drawer7 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='drawer-top-head' to="/Drawers"><h1>CHEST OF DRAWERS</h1></Link> 
            </div>
            
            <div className="row">
               
                <div className="col-11 col-sm-11 col-md-6 guncol1 order-2 order-md-1">
                {/* <LazyLoadImage className='chikkimirror2' src={chikkimirror2} alt="" loading='lazy' /> */}
                  <div className="product-description-signle-container">
                      <p className='product-description-para-3'><span className='description-heads'>NAME:</span> RED ROSE CABINET </p>
                      <p className='product-description-para-3'><span className='description-heads'>DESCRIPTION:</span> A series of cabinets in a deep rose finish.

                      </p>
                  </div>
                </div>
               
                <div className="col-12 col-sm-12 col-md-6 guncol2 order-1 order-md-2">
                <LazyLoadImage className='redrose1' src={redrose1} alt="" loading='lazy' />
                </div>
            </div>
            <div className="arrow-container">
           <Link to="/Drawer6"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /></Link> 
            <Link to="/Drawer8"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /></Link>
            </div>
        </div>
    </div>
  )
}

export default Drawer7