import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Accessories8.css'
import contemprory1 from '../Images/contemprory1.webp'
import contemprory2 from '../Images/contemprory2.webp'
import contemprory3 from '../Images/contemprory3.webp'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Accessories8 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='top-head-accessories' to="/Accessories"><h1>ACCESSORIES</h1></Link>  
            </div>
            
            <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='w-100 contemprorytray1' src={contemprory1} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='contemprorytray2' src={contemprory2} alt="" loading='lazy' />
                  <div className="product-description-contemprory">
                      <p className='product-description-para-1'><span className='description-heads'>NAME:</span> THE CONTEMPRORY TEA TRAYS </p>
                      <p className='product-description-para-1'><span className='description-heads'>DESCRIPTION:</span> Polished wooden tray and brass detailing handles.
                      </p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-2 contemprorytray3-col3">
                <LazyLoadImage className='contemprorytray3' src={contemprory3} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-4">
                <LazyLoadImage className='contemprorytray4' src={contemprory1} alt="" loading='lazy' />
                </div>
            </div>
            <div className="product-description-mobile">
            <p className='product-description-para-1'><span className='description-heads'>NAME:</span> THE CONTEMPRORY TEA TRAYS </p>
                      <p className='product-description-para-1'><span className='description-heads'>DESCRIPTION:</span> Polished wooden tray and brass detailing handles.
                      </p>
                  </div>
            <div className="arrow-container">
           <Link to="/Accessories7"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /> </Link> 
           <Link to="/Accessories9"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /> </Link>
            </div>
        </div>
    </div>
  )
}

export default Accessories8