import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Mirrordetail1.css'
import chikkimirror1 from '../DetailImages/Chikki Mirror1.webp'
import chikkimirror2 from '../DetailImages/Chikki Mirror 2.webp'
import chikkimirror3 from '../DetailImages/Chikki mirror3.webp'
import chikkimirror4 from '../DetailImages/Chikki mirror4.webp'
import arrowright from '../DetailImages/Arrowright.png'
import arrowleft from '../DetailImages/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Mirrordetail1 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='mirror-top-head' to="/Mirrors"><h1 >Mirrors</h1></Link>
            </div>
            
            <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='w-100 chikkimirror1' src={chikkimirror1} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='chikkimirror2' src={chikkimirror2} alt="" loading='lazy' />
                  <div className="product-description">
                      <p className='product-description-para-1'><span className='description-heads'>NAME:</span> CHIKKI MIRROR </p>
                      <p className='product-description-para-1'> <span className='description-heads'>DESCRIPTION:</span> Handcrafted mirror with wooden detailing.
                      </p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-2 chikkimirror3-col3">
                <LazyLoadImage className='chikkimirror3' src={chikkimirror3} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-4">
                <LazyLoadImage className='w-100 chikkimirror4' src={chikkimirror4} alt="" loading='lazy' />
                </div>
            </div>
            <div className="product-description-mobile">
                      <p className='product-description-para'><span className='description-heads'>NAME:</span> CHIKKI MIRROR <br/>
                       <span className='description-heads'>DESCRIPTION:</span> Handcrafted mirror with wooden detailing.
                      </p>
                  </div>
            <div className="arrow-container">
           <Link to="/MirrorDetail15"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /> </Link> 
           <Link to="/MirrorDetail2"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /> </Link>
            </div>
        </div>
    </div>
  )
}

export default Mirrordetail1