import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Accessories12.css'
import cutout1 from '../Images/cutout1.jpg'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Accessories12 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='top-head-accessories' to="/Accessories"><h1>ACCESSORIES</h1></Link>  
            </div>
            
            <div className="row">
               
                <div className="col-11 col-sm-11 col-md-6 guncol1 order-2 order-md-1">
                
                  <div className="product-description-signle-container">
                      <p className='product-description-para-3'><span className='description-heads'>NAME:</span> CUTOUT LEDGE </p>
                      <p className='product-description-para-3'> <span className='description-heads'>DESCRIPTION:</span>  A ledge made out wood with a handcarved motif design in a light wooden polish.
                      </p>
                  </div>
                </div>
               
                <div className="col-12 col-sm-12 col-md-6 guncol2 order-1 order-md-2">
                <LazyLoadImage className='cutout1' src={cutout1} alt="" loading='lazy' />
                </div>
            </div>
            <div className="arrow-container">
           <Link to="/Accessories11"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /></Link> 
            <Link to="/Accessories13"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /></Link>
            </div>
        </div>
    </div>
  )
}

export default Accessories12