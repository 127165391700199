import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Mirrordetail11.css'
import Goldfoil1 from '../DetailImages/Goldfoil1.jpg'
import Goldfoil2 from '../DetailImages/Goldfoil2.jpg'
import Goldfoil3 from '../DetailImages/Goldfoil3.jpg'
import Goldfoil4 from '../DetailImages/Goldfoil4.jpg'
import arrowright from '../DetailImages/Arrowright.png'
import arrowleft from '../DetailImages/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Mirrordetail11 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='mirror-top-head' to="/Mirrors"><h1 >Mirrors</h1></Link>
            </div>
            
            <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='w-100' src={Goldfoil1} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='Goldfoil2' src={Goldfoil2} alt="" loading='lazy' />
                  <div className="product-description">
                      <p className='product-description-para-1'><span className='description-heads'>NAME:</span> FLUER GOLD MIRROR </p>
                      <p className='product-description-para-1'><span className='description-heads'>DESCRIPTION:</span>  Handpainted wooden mirror with gold leaf inlay.
                      </p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-2 Goldfoil3-col3">
                <LazyLoadImage className='Goldfoil3' src={Goldfoil3} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-4">
                <LazyLoadImage className='w-100' src={Goldfoil4} alt="" loading='lazy' />
                </div>
            </div>
            <div className="product-description-mobile">
                      <p className='product-description-para'><span className='description-heads'>NAME:</span> FLUER GOLD MIRROR <br/>
                       <span className='description-heads'>DESCRIPTION:</span>  Handpainted wooden mirror with gold leaf inlay.
                      </p>
                  </div>
            <div className="arrow-container">
           <Link to="/MirrorDetail10"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /> </Link> 
           <Link to="/MirrorDetail12"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /> </Link>
            </div>
        </div>
    </div>
  )
}

export default Mirrordetail11