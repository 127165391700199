import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Doors26.css'
import pupperdoor1 from '../Images/pupperdoor1.webp'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Doors26 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='top-head-accessories' to="/Doors"><h1>DOORS AND GATES</h1></Link>  
            </div>
            
            <div className="row">
               
                <div className="col-11 col-sm-11 col-md-6 guncol1 order-2 order-md-1">
                {/* <LazyLoadImage className='chikkimirror2' src={chikkimirror2} alt="" loading='lazy' /> */}
                  <div className="product-description-signle-container">
                      <p className='product-description-para-3'><span className='description-heads'>NAME:</span>  PUPPER DOOR </p>
                      <p className='product-description-para-3'><span className='description-heads'>DESCRIPTION:</span> This is an entrance gate for another residence weve designed for dog lovers to proudly showcase their love for animals with custom laser cut metal dog motifs on their main gate.          
                      </p>
                  </div>
                </div>
               
                <div className="col-12 col-sm-12 col-md-6 guncol2 order-1 order-md-2">
                <LazyLoadImage className='pupperdoor1' src={pupperdoor1} alt="" loading='lazy' />
                </div>
            </div>
            <div className="arrow-container">
           <Link to="/Doors25"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /></Link> 
            <Link to="/Doors27"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /></Link>
            </div>
        </div>
    </div>
  )
}

export default Doors26