import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Table7.css'
import kaju1 from '../Images/kaju1.webp'
import kaju2 from '../Images/kaju2.webp'
import kaju3 from '../Images/kaju3.webp'
import kaju4 from '../Images/kaju4.webp'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Table7 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='mirror-top-head' to="/Mirrors"><h1 >TABLES</h1></Link>
            </div>
            
            <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='w-100 kaju1' src={kaju1} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='kaju2' src={kaju4} alt="" loading='lazy' />
                  <div className="product-description">
                  <p className='product-description-para-1'><span className='description-heads'>NAME:</span> KAJU TABLE </p>
                  <p className='product-description-para-1'>   <span className='description-heads'>DESCRIPTION:</span> Wooden table with white polish inlay in the shape of diamonds. This is a minimalistic style with a little bit of ornamentation to highlight the piece.
                      </p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-3 kaju3-col3">
                <LazyLoadImage className='kaju3' src={kaju3} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='kaju4' src={kaju2} alt="" loading='lazy' />
                </div>
            </div>
            <div className="product-description-mobile">
            <p className='product-description-para-1'><span className='description-heads'>NAME:</span> KAJU TABLE </p>
                  <p className='product-description-para-1'>   <span className='description-heads'>DESCRIPTION:</span> Wooden table with white polish inlay in the shape of diamonds. This is a minimalistic style with a little bit of ornamentation to highlight the piece.
                      </p>
                  </div>
            <div className="arrow-container">
           <Link to="/Table19"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /> </Link> 
           <Link to="/Table21"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /> </Link>
            </div>
        </div>
    </div>
  )
}

export default Table7