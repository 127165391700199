import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Chair1.css'
import minimalists1 from '../Images/minimalists1.webp'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Chair1 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid detailed-turya-main-container">
            <div className="inner-container1">
            <Link className='top-head-accessories' to="/Seating"><h1>SEATING</h1></Link>  
            </div>
            <div className="second-inner-turya-detailed-container">
            <div className="row">
               
                <div className="col-11 col-sm-11 col-md-6 guncol1 order-2 order-md-1">
                {/* <LazyLoadImage className='chikkimirror2' src={chikkimirror2} alt="" loading='lazy' /> */}
                  <div className="product-description-signle-container">
                      <p className='product-description-para-3'><span className='description-heads'>NAME:</span>A MINIMALISTS CHAIR </p>
                      <p className='product-description-para-3'><span className='description-heads'>DESCRIPTION:</span> Two chairs inspired by a minimal, geometric design that is light and sleek. We designed these chairs to be used as dining chairs with different finishes such as polish and fabric cushions to theme in the space.
                      </p>
                  </div>
                </div>
               
                <div className="col-12 col-sm-12 col-md-6 guncol2 order-1 order-md-2">
                <LazyLoadImage className='minimalists1' src={minimalists1} alt="" loading='lazy' />
                </div>
            </div>
            </div>
        </div>
            <div className="arrow-container">
           <Link to="/Swings1"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /></Link> 
            <Link to="/Chair2"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /></Link>
            </div>
    </div>
  )
}

export default Chair1