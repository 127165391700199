import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Accessories23.css'
import naturecoasters1 from '../Images/naturecoasters1.webp'
import naturecoasters2 from '../Images/naturecoasters2.webp'
import naturecoasters3 from '../Images/naturecoasters3.webp'
import naturecoasters4 from '../Images/naturecoasters4.webp'
import arrowright from '../Images/Arrowright.png'
import arrowleft from '../Images/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Accessories23 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='top-head-accessories' to="/Accessories"><h1>ACCESSORIES</h1></Link>  
            </div>
            
            <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='naturecoasters1' src={naturecoasters1} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='naturecoasters2' src={naturecoasters2} alt="" loading='lazy' />
                  <div className="product-description-nature">
                      <p className='product-description-para-1'><span className='description-heads'>NAME:</span> AN ODE TO NATURE COASTERS</p>
                      <p className='product-description-para-1'> <span className='description-heads'>DESCRIPTION:</span> Polished wood with metal engraved inlay inspired by illustrations of nature that are a luxe addition to the home and elevates your tea and coffee or any other drink. 
                        We keep revamping these designs and adding to our collection of custom coasters, contact us to order your set! 
                      </p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-2 naturecoasters3-col3">
                <LazyLoadImage className='naturecoasters3' src={naturecoasters3} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-4">
                <LazyLoadImage className='naturecoasters4' src={naturecoasters4} alt="" loading='lazy' />
                </div>
            </div>
            <div className="product-description-mobile">
            <p className='product-description-para-1'><span className='description-heads'>NAME:</span> AN ODE TO NATURE COASTERS</p>
                      <p className='product-description-para-1'> <span className='description-heads'>DESCRIPTION:</span> Polished wood with metal engraved inlay inspired by illustrations of nature that are a luxe addition to the home and elevates your tea and coffee or any other drink. 
                               We keep revamping these designs and adding to our collection of custom coasters, contact us to order your set! 
                      </p>
                  </div>
            <div className="arrow-container">
           <Link to="/Accessories22"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /> </Link> 
           <Link to="/Accessories24"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /> </Link>
            </div>
        </div>
    </div>
  )
}

export default Accessories23