import React from 'react'
import NavBar from '../../../../../Navbar/Navbar';
import './Mirrordetail9.css'
import Bahunia1 from '../DetailImages/Bahunia1.jpg'
import Bahunia2 from '../DetailImages/Bahunia2.jpg'
import Bahunia3 from '../DetailImages/Bahunia3.jpg'
import Bahunia4 from '../DetailImages/Bahunia4.jpg'
import arrowright from '../DetailImages/Arrowright.png'
import arrowleft from '../DetailImages/Arrowleft.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Mirrordetail9 = () => {
  return (
    <div>
        <NavBar className="custom-navbars" />
        <div className="container-fluid">
            <div className="inner-container1">
            <Link className='mirror-top-head' to="/Mirrors"><h1 >Mirrors</h1></Link>
            </div>
            
            <div className="row">
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='w-100' src={Bahunia1} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-3">
                <LazyLoadImage className='bahunia2' src={Bahunia2} alt="" loading='lazy' />
                  <div className="product-description">
                      <p className='product-description-para-1'><span className='description-heads'>NAME:</span> BAHUNIA LEAF MIRROR </p>
                      <p className='product-description-para-1'><span className='description-heads'>DESCRIPTION:</span> Polished wood with copper leaf motif inlay inspired by the bahunia tree.
                      </p>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-2 bahunia3-col3">
                <LazyLoadImage className='bahunia3' src={Bahunia3} alt="" loading='lazy' />
                </div>
                <div className="col-6 col-sm-6 col-md-4">
                <LazyLoadImage className='w-100' src={Bahunia4} alt="" loading='lazy' />
                </div>
            </div>
            <div className="product-description-mobile">
                      <p className='product-description-para'><span className='description-heads'>NAME:</span> BAHUNIA LEAF MIRROR <br/>
                       <span className='description-heads'>DESCRIPTION:</span> Polished wood with copper leaf motif inlay inspired by the bahunia tree.
                      </p>
                  </div>
            <div className="arrow-container">
           <Link to="/MirrorDetail8"><LazyLoadImage className='arrowleft' src={arrowleft} alt="" loading='lazy' /> </Link> 
           <Link to="/MirrorDetail10"><LazyLoadImage className='arrowright' src={arrowright} alt="" loading='lazy' /> </Link>
            </div>
        </div>
    </div>
  )
}

export default Mirrordetail9